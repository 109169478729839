import { Camera } from 'views/WorkflowsAndActions/Actions/ActionTypes/TriggerDevice/Dialog/Steps/DeviceActions/DeviceCard/Devices/Camera/type'; // TODO: Bu type'ı farklı bir yere çekebiliriz artık
import { DynamicListDefinition } from '@borda/cat-core';

export const initialColumnDynamicData: DynamicListDefinition<Camera> = {
  columns: [
    {
      column: 'host',
      visible: true,
      width: 100
    },
    {
      column: 'name',
      visible: true,
      width: 150
    },
    {
      column: 'description',
      visible: true,
      width: 150
    },
    {
      column: 'locationFullPath',
      visible: true,
      width: 200
    },
    {
      column: 'lastRecordTime',
      visible: true,
      width: 150
    },
    {
      column: 'isConnected',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'isConnected', direction: 'desc' }]
};
