export type FirstLevelRoute =
  | 'asset'
  | 'asset-configuration'
  | 'asset-dashboard'
  | 'attribute-configuration'
  | 'contract'
  | 'design-system'
  | 'location'
  | 'integration'
  | 'metrics-configuration'
  | 'notification'
  | 'profile-settings'
  | 'protection-calendar'
  | 'request'
  | 'tag-configuration'
  | 'task-configuration'
  | 'task'
  | 'patient'
  | 'plan'
  | 'realtime-tracking'
  | 'medical-units'
  | 'user'
  | 'workflow';

type FirstLevelRouteWithPrefix = `/${FirstLevelRoute}`;

export const ASSETCONFIGURATION: FirstLevelRouteWithPrefix = '/asset-configuration';
export const CATEGORIES = `${ASSETCONFIGURATION}/categories`;
export const CATEGORYTASKS = `${ASSETCONFIGURATION}/category-tasks`;
export const FORMS = `${ASSETCONFIGURATION}/forms`;
export const FORMBUILDER = `${FORMS}/form-builder`;
export const CATEGORY_PROTECTION_REQUIREMENT = `${CATEGORIES}/protection-requirement`;
export const CATEGORY_PLAN_NOT_REQUIRED_DETAIL = 'plan-not-required/:notificationId';

export const METRICSCONFIGURATION: FirstLevelRouteWithPrefix = `/metrics-configuration`;
export const METRICSCONFIGURATION_LIST = `${METRICSCONFIGURATION}/metrics`;
export const METRICSCONFIGURATION_DETAIL = `${METRICSCONFIGURATION}/metrics/:metricId`;

export const TASKCONFIGURATION: FirstLevelRouteWithPrefix = '/task-configuration';
export const BREAKDOWN = `${TASKCONFIGURATION}/breakdown`;
export const MAINTENANCE = `${TASKCONFIGURATION}/maintenance`;
export const CALIBRATION = `${TASKCONFIGURATION}/calibration`;
export const RETIREMENT = `${TASKCONFIGURATION}/retirement`;

export const PUBLIC = '/public';
export const LINKEXPIRED = `${PUBLIC}/invitation-link-expired`;
export const REGISTERFIRSTPAGE = `${PUBLIC}/user-register-landing`;
export const REGISTERSECONDPAGE = `${PUBLIC}/user-register`;

export const PROFILESETTINGS: FirstLevelRouteWithPrefix = '/profile-settings';
export const ACCOUNTINFORMATION = `${PROFILESETTINGS}/account-information`;
export const LOCALIZATIONSETTINGS = `${PROFILESETTINGS}/localization-settings`;
export const DEVELOPEROPTIONS = `${PROFILESETTINGS}/developer-options`;
export const MYEXPORTS = `${PROFILESETTINGS}/my-files`;
export const USER_NOTIFICATION_SETTINGS = `${PROFILESETTINGS}/notification-settings`;
export const NOTIFICATIONS = `${ACCOUNTINFORMATION}/notifications`;
export const VIDEO_CAPTURED_NOTIFICATION_DETAIL = `${NOTIFICATIONS}/video-captured/:eventId`;

export const TASK: FirstLevelRouteWithPrefix = '/task';
export const TASKLIST = '/task/tasks';
export const TASK_DETAIL = `${TASKLIST}/breakdown/:taskId`;
export const WAITINGFORCONFIRMATIONLIST = `${TASK}/waiting-for-confirmation`;
export const WAITINGFORCONFIRMATIONDETAIL = `${WAITINGFORCONFIRMATIONLIST}/breakdown/:breakdownId`;
export const PROTECTION_LIST = `${TASKLIST}/protections/:protectionId`;
export const MAINTENANCE_DETAIL = `${TASKLIST}/maintenance/:maintenanceId`;
export const CALIBRATION_DETAIL = `${TASKLIST}/calibration/:calibrationId`;

export const PLAN: FirstLevelRouteWithPrefix = '/plan';
export const PROTECTION_CALENDAR = `${PLAN}/protection-calendar`;
export const PLANS = `${PLAN}/plans`;

export const PLAN_LIST = `${PLANS}/list`;
export const PLAN_EDIT = ':id';
export const PLAN_CREATE_START = `${PLANS}/add/generalInformation`;
export const PLAN_MODIFY_GENERAL_INFORMATION = `${PLANS}/:mode/:planId/generalInformation`;
export const PLAN_MODIFY_RESPONSIBLE_NOTIFICATION = `${PLANS}/:mode/:planId/responsibleNotification`;
export const PLAN_MODIFY_SUMMARY = `${PLANS}/:mode/:planId/summary`;

export const REQUEST: FirstLevelRouteWithPrefix = '/request';
export const MYREQUESTSLIST = `${REQUEST}/my-requests`;
export const MYREQUESTSDETAIL = `${MYREQUESTSLIST}/breakdown/:breakdownId`;
export const BREAKDOWN_OPEN = `${MYREQUESTSLIST}/open`;
export const BREAKDOWN_REOPEN = `${MYREQUESTSLIST}/reopen`;

export const ERROR401 = '/errors/error-401';
export const ERROR404 = '/errors/error-404';
export const ERROR500 = '/errors/error-500';

export const DESIGNSYSTEM: FirstLevelRouteWithPrefix = '/design-system';
export const DESIGNSYSTEM_SHOWCASE = `${DESIGNSYSTEM}/showcase`;

export const ASSET: FirstLevelRouteWithPrefix = '/asset';
export const ASSETS = `${ASSET}/assets`;
export const MYASSETS = `${ASSET}/my-assets`;
export const MYASSETSLISTDETAIL = `${MYASSETS}/:id`;
export const PASSIVE_ASSETS_DETAIL = 'passive-notification/:notificationId';
export const TRANSFER_ASSETS_DETAIL = 'transfer-notification/:notificationId';
export const BULK_UPDATED_ASSETS_DETAIL = 'multiple-update/:notificationId';
export const MULTI_CUSTODY_ASSIGNED_ASSETS_DETAIL = 'multiple-custody/:notificationId';
export const MULTI_CUSTODY_REMOVED_ASSETS_DETAIL = 'multiple-custody-removed/:notificationId';
export const CUSTODY_APPROVED_ASSETS_DETAIL = 'custody-approved/:notificationId';
export const CUSTODY_DENIED_ASSETS_DETAIL = 'custody-denied/:notificationId';
export const CUSTODY_REQUEST_DETAILL = 'custody-request';
export const MYASSETS_CUSTODY_REQUESTS = `${MYASSETS}/list/${CUSTODY_REQUEST_DETAILL}`;

export const ASSET_DETAIL = `${ASSETS}/:id`;
export const ASSET_BULK_EDIT = `${ASSETS}/bulk-edit`;
export const ASSET_ADD = `${ASSETS}/add`;
export const ASSETLIST = `${ASSETS}/list`;

export const CONTRACT: FirstLevelRouteWithPrefix = '/contract';
export const CONTRACTS = `${CONTRACT}/contracts`;
export const CONTRACT_LIST = `${CONTRACTS}/list`;
export const CONTRACT_CREATE_START = `${CONTRACTS}/add/generalInformation`;
export const CONTRACT_MODIFY_GENERAL_INFORMATION = `${CONTRACTS}/:mode/:contractId/generalInformation`;
export const CONTRACT_MODIFY_METRICS = `${CONTRACTS}/:mode/:contractId/metrics`;
export const CONTRACT_MODIFY_SUMMARY = `${CONTRACTS}/:mode/:contractId/summary`;

export const CONTRACT_EDIT = ':id';

export const USER: FirstLevelRouteWithPrefix = '/user';
export const USER_LIST = `${USER}/users`;
export const USER_DEPARTMENTS = `${USER}/departments`;
export const USER_CREATE = `${USER_LIST}/user-create/:userType`;
export const USER_INFORMATION = `${USER_LIST}/information/:userId`;
export const USER_EDIT = `${USER_LIST}/edit/:userId`;

export const LOCATION: FirstLevelRouteWithPrefix = '/location';
export const LOCATION_LIST = `${LOCATION}/locations`;
export const ACCESS_POINTS = `${LOCATION}/access-points`;
export const CCTV_ADD = `${LOCATION}/cctv/cctv_add?branchId=:branchId`;
export const CCTV_DETAIL = `${LOCATION}/cctv/:id`;
export const MAP_SETTINGS = `${LOCATION}/map-settings`;
export const CAMERAS = `${LOCATION}/cameras`;

export const ASSETDASHBOARD: FirstLevelRouteWithPrefix = '/asset-dashboard';
export const REALTIME = `${ASSETDASHBOARD}/realtime`;
export const UTILIZATION = `${ASSETDASHBOARD}/utilization`;

export const REALTIME_TRACKING: FirstLevelRouteWithPrefix = '/realtime-tracking';
export const REALTIME_MAP = 'realtime-map';
export const REALTIME_MAP_PAGE = `${REALTIME_TRACKING}/${REALTIME_MAP}`;

export const INTEGRATION: FirstLevelRouteWithPrefix = '/integration';
export const CYLERA_LIST = `${INTEGRATION}/cylera`;
export const CYLERA_ASSET_ADD = `${CYLERA_LIST}/asset-add`;
export const RTLS_CONFIG = 'rtls-config';
export const RTLS_CONFIG_PAGE = `${INTEGRATION}/${RTLS_CONFIG}`;

export const TAG_CONFIGURATIONS: FirstLevelRouteWithPrefix = '/tag-configuration';
export const TRACKABLE_LIST = `${TAG_CONFIGURATIONS}/trackables`;

export const MEDICAL_UNITS: FirstLevelRouteWithPrefix = '/medical-units';
export const MEDICAL_UNIT_MANAGEMENT = `${MEDICAL_UNITS}/medical-units`;

export const PATIENT: FirstLevelRouteWithPrefix = '/patient';
export const PATIENTS = `${PATIENT}/patients`;
export const PATIENT_LIST = `${PATIENTS}/list`;
export const PATIENT_DETAIL = `${PATIENTS}/:id`;
export const NOTIFICATION: FirstLevelRouteWithPrefix = '/notification';
export const NOTIFICATION_MANAGEMENT = `${NOTIFICATION}/notifications`;

export const ATTRIBUTE_CONFIGURATION: FirstLevelRouteWithPrefix = '/attribute-configuration';
export const ASSET_ATTRIBUTE = `${ATTRIBUTE_CONFIGURATION}/asset`;
export const PATIENT_ATTRIBUTE = `${ATTRIBUTE_CONFIGURATION}/patient`;
export const USER_ATTRIBUTE = `${ATTRIBUTE_CONFIGURATION}/user`;
export const LOCATION_ATTRIBUTE = `${ATTRIBUTE_CONFIGURATION}/location`;

export const ATTRIBUTE_ADD = '/add';
export const ATTRIBUTE_EDIT = '/edit/:attributeId';

export const WORKFLOW: FirstLevelRouteWithPrefix = '/workflow';
export const WORKFLOWS = `${WORKFLOW}/workflows`;
export const WORKFLOW_LIST = `${WORKFLOWS}/list`;
export const WORKFLOW_EDIT = ':template/:id';
export const WORKFLOW_AND_ACTIONS_START = `${WORKFLOWS}/add/workflowSettings/:template`;
export const WORKFLOW_MODIFY_WORKFLOW_SETTINGS = `${WORKFLOWS}/:mode/:template/:workflowId/workflowSettings`;
export const WORKFLOW_MODIFY_SET_ACTIONS = `${WORKFLOWS}/:mode/:template/:workflowId/setActions`;
export const WORKFLOW_MODIFY_SUMMARY = `${WORKFLOWS}/:mode/:template/:workflowId/summary`;
export const ACTIONS = `${WORKFLOW}/actions`;
export const ACTION_LIST = `${ACTIONS}/list`;
