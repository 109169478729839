import { Camera } from 'views/WorkflowsAndActions/Actions/ActionTypes/TriggerDevice/Dialog/Steps/DeviceActions/DeviceCard/Devices/Camera/type';
import { ColumnSort, PagedResult } from '@borda/cat-core';
import { PagedRequestOptions } from 'store/common';

export type Agent = {
  branchId: string;
  id: string;
  isConnected: boolean;
  lastConnectionTime: string;
  name: string;
};

export interface CameraListFilter {
  branchIds?: string[];
  isConnected?: boolean;
  searchText?: string;
}

export const defaultFilter: CameraListFilter = {
  branchIds: [],
  isConnected: null,
  searchText: null
};

export type CameraStatusCount = {
  connectedCount: number;
  disconnectedCount: number;
};

export type CameraListResponse = PagedResult<Camera> & {
  cameraStatusCounts: CameraStatusCount;
  disconnectedAgentCount: number;
  page: number;
};

export type CameraListRequest = PagedRequestOptions & {
  filter: CameraListFilter;
  sorts: ColumnSort<Camera>[];
};
